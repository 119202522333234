<template>
    <loading-animation class="card bs-4" v-if="DataLoading"></loading-animation>
    <div v-else class="card bs-4 p-0">
        <h5 class="bb-1 p-3">Purchase Orders Created</h5>
        <tabs class="response">
            <tab class="p-0 pb-3" title="Accepted List">
                <div v-for="(record, index) in dataApproved" v-bind:key="record.id">
                    <div class="pb-3" v-bind:style="getStyle(index)">
                        <div class="row pl-3 pt-3 pr-5">
                            <div class="col-3 label">Product ID</div>
                            <div class="col-3 p-1 b-1 text-muted">{{ record.id }}</div>
                            <div class="col-3 label">Date</div>
                            <div class="col-3 p-1 b-1 text-muted">{{ record.date }}</div>
                        </div>
                        <div class="row pl-3 pt-3 pr-5">
                            <div class="col-3 label">Status</div>
                            <div class="col-3 p-1 b-1 text-muted">{{ record.status }}</div>
                            <div class="col-3 label">Remarks</div>
                            <div class="col-3 p-1 b-1 text-muted">{{ record.remarks }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="dataApproved.length===0" class="text-center mt-5">No data available.</div>
            </tab>
            <tab class="p-0 pb-3" title="Rejected List">
                <div v-for="(record, index) in dataRejected" v-bind:key="record.id">
                    <div class="pb-3" v-bind:style="getStyle(index)">
                        <div class="row pl-3 pt-3 pr-5">
                            <div class="col-3 label">Product ID</div>
                            <div class="col-3 p-1 b-1 text-muted">{{ record.id }}</div>
                            <div class="col-3 label">Date</div>
                            <div class="col-3 p-1 b-1 text-muted">{{ record.date }}</div>
                        </div>
                        <div class="row pl-3 pt-3 pr-5">
                            <div class="col-3 label">Status</div>
                            <div class="col-3 p-1 b-1 text-muted">{{ record.status }}</div>
                            <div class="col-3 label">Remarks</div>
                            <div class="col-3 p-1 b-1 text-muted">{{ record.remarks }}</div>
                        </div>
                    </div>
                </div>
                <div v-if="dataRejected.length===0" class="text-center mt-5">No data available.</div>
            </tab>
        </tabs>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../../data/urls';

export default {
    name  : 'OrderResponses',
    props : { id : { type : String } },
    data () {
        return {
            DataLoading  : false,
            dataApproved : [],
            dataRejected : []
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.warehouseAdmin.request.history, { id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.dataApproved = json.approved;
                that.dataRejected = json.rejected;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
            that.DataLoading = false;
        },
        getStyle (index) {
            if (index % 2 === 0) {
                const data = {};
                return data;
            } else {
                const data = { background : '#eeeeee' };
                return data;
            }
        }
    }
};
</script>

<style lang="scss">
.response {
    .tabs-header {
        display: flex;
        flex-direction: row;
        text-align: center;

        li {
            flex-grow: 1;
        }
    }
}
</style>

<style scoped>
.b-1 {
    border-radius: 4px;
    background-color: white;
}

.label {
    font-weight: 500;
}
</style>
