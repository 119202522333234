<template>
    <div class="p-1">
        <h4>Request Order Details</h4>
        <div class="fl-te-t">
            <div style="width:49%">
                <order-details-component :id="id.toString()" class="w-49r"/>
            </div>
            <div style="width:50%">
                <order-responses :id="id.toString()" class="w-49r"/>
            </div>
        </div>
    </div>
</template>

<script>
import OrderDetailsComponent from './Components/OrderDetailsComponent';
import OrderResponses from './Components/OrderResponses';

export default {
    name       : 'RequestListPage',
    components : { OrderResponses, OrderDetailsComponent },
    data () {
        return {
            data      : '',
            id        : this.$route.params.id,
            responses : ''
        };
    }
};
</script>

<style scoped>

</style>
