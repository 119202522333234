<template>
    <loading-animation class="card bs-4" v-if="DataLoading"></loading-animation>
    <div v-else>
        <div class="card bs-4 p-0 pb-3 mb-4">
            <h5 class="bb-1 p-3">Order Details</h5>
            <div v-if="data" class="row pl-3 pt-3 pr-5">
                <div class="col-3 label">Request ID</div>
                <div class="col-3 p-1 b-1 text-muted">{{ data.request_id }}</div>
                <div class="col-3 label">Created by</div>
                <div class="col-3 p-1 b-1 text-muted">{{ data.created_by }}</div>
            </div>
            <div v-if="data" class="row mt-2 pl-3 pt-3 pr-5">
                <div class="col-3 label">Status</div>
                <div class="col-3 p-1 b-1 text-muted">{{ data.status }}</div>
                <div class="col-3 label">Date</div>
                <div class="col-3 p-1 b-1 text-muted">{{ data.created_date }}</div>
            </div>
            <custom-simple-table class="table-borderless mt-4 mb-0" v-if="data" ref="table" title="Items"
                                 :fields="fields" :data="data.items"/>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '../../../../../data/urls';

export default {
    name  : 'OrderDetailsComponent',
    props : { id : { type : String } },
    data () {
        return {
            data        : [],
            DataLoading : false,
            fields      : [
                {
                    name      : 'id',
                    sortField : 'id',
                    title     : 'ID'
                },
                {
                    name      : 'product_name',
                    sortField : 'product_name',
                    title     : 'Product'
                },
                {
                    name      : 'quantity',
                    sortField : 'quantity',
                    title     : 'Quantity'
                }
            ]
        };
    },
    mounted () {
        this.loadDetails();
    },
    methods : {
        async loadDetails () {
            const that = this;
            that.DataLoading = true;
            const response = await axios.form(urls.warehouseAdmin.request.details, { id : that.id });
            const json = response.data;
            if (json.error === false) {
                that.data = json.data;
                that.DataLoading = false;
            } else {
                that.$notify('Details not found', 'Error', {
                    type : 'danger'
                });
                that.DataLoading = false;
            }
        }
    }
};
</script>

<style scoped>
.b-1 {
    border-radius: 4px;
}

.label {
    font-weight: 500;
}
</style>
